import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import { Seo } from 'components/helpers'
import { DefaultLayout } from 'components/layouts'

import PostShowView from 'views/PostShow'

interface IndexPageProps extends PageProps {
  data: {
    post: BlogPost
  }
}

const BlogShowPage: React.FC<IndexPageProps> = ({ data }) => {
  const { post } = data

  return (
    <DefaultLayout>
      <Seo title={`${post.title} | Blog`} description={post.subtitle} />
      <PostShowView post={post ?? {}} />
    </DefaultLayout>
  )
}

export const query = graphql`
  query PostQuery($slug: String) {
    post: apiPosts(slug: { eq: $slug }) {
      category {
        slug
        title
      }
      title
      subtitle
      body
      image
      video
      relatedPosts {
        id
        slug
        category {
          slug
        }
        title
        subtitle
        image
      }
    }
  }
`

export default BlogShowPage

import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'

import { Block, Carousel, Text, Title } from 'components/shared'
import { ContentReset, Ribbon, Share } from 'components/helpers'
import { Post } from 'components/app'

import * as S from './styled'

interface ViewProps {
  post: BlogPost
}

const PostShowView: React.FC<ViewProps> = ({ post }) => (
  <>
    <Block tag="section">
      <Block
        tag="header"
        bgColor="brown800"
        textAlign="center"
        paddingTop={{ xxxs: 'size64', sm: 'size88' }}
        paddingBottom={{ xxxs: 'size48', sm: 'size64' }}
      >
        <Container>
          <Row justify="center">
            <Col xs={12} md={10} align="center">
              <Text color="white" marginBottom="size12" uppercase>
                {post.category.title}
              </Text>
              <Title
                level="h1"
                size="h2"
                color="yellow400"
                lineHeight={1}
                uppercase
              >
                {post.title}
              </Title>
              <Share title={post.title} marginTop="size24" />
            </Col>
          </Row>
        </Container>
      </Block>

      {!post.image?.includes('no-photo.png') && (
        <S.Cover>
          <Container>
            <Block position="relative" width="100%" height={400}>
              <S.Image src={post.image} alt={post.title || 'post-cover'} />
            </Block>
          </Container>
        </S.Cover>
      )}

      <Block
        tag="article"
        paddingTop={{ xxxs: 'size24', sm: 'size48' }}
        paddingBottom={{ xxxs: 'size64', sm: 'size88' }}
      >
        <Container>
          <Row justify="center">
            <Col xs={11} md={10}>
              <ContentReset body={post.body} />
            </Col>
          </Row>
        </Container>
      </Block>

      {post.relatedPosts.length > 0 && (
        <Block tag="footer" paddingBottom={{ xxxs: 'size64', sm: 'size88' }}>
          <Container>
            <Row justify="center">
              <Col xs={12} md={10}>
                <Title
                  size="h3"
                  icon={{
                    name: 'news',
                    color: 'brown800',
                    width: 'size20',
                    height: 'size20',
                    marginLeft: 'size48',
                    marginRight: 'size8'
                  }}
                  appearance="section"
                  marginBottom="size48"
                  uppercase
                >
                  Leia também
                </Title>
              </Col>
              <Col xs={12} md={10}>
                <Carousel
                  swiper={{
                    initialSlide: 0,
                    observer: true,
                    watchOverflow: true,
                    simulateTouch: false,
                    autoplay: {
                      delay: 4000
                    },
                    spaceBetween: 32,
                    slidesPerView: 2,
                    breakpoints: {
                      0: {
                        spaceBetween: 0,
                        slidesPerView: 1
                      },
                      768: {
                        spaceBetween: 32,
                        slidesPerView: 2
                      }
                    }
                  }}
                >
                  {post.relatedPosts.map((relatedPost) => (
                    <Post
                      key={relatedPost.id}
                      category={relatedPost.category.slug}
                      slug={relatedPost.slug}
                      title={relatedPost.title}
                      subtitle={relatedPost.subtitle}
                      image={relatedPost.image}
                    />
                  ))}
                </Carousel>
              </Col>
            </Row>
          </Container>
        </Block>
      )}
    </Block>
  </>
)

export default PostShowView

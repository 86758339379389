import styled, { css } from 'styled-components'
import { parseSize } from 'utils/css'

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`

export const Cover = styled.div`
  position: relative;
  width: 100%;
  height: ${parseSize(400)};
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
  }

  ${({ theme: { colors } }) => css`
    &:before {
      background-color: ${colors.brown800};
    }
  `}
`
